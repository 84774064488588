import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../Service/api.service';
import { Useraccessmapping } from '../Models/useraccessmapping';

@Component({
  selector: 'app-emplogin',
  templateUrl: './emplogin.component.html',
  styleUrls: ['./emplogin.component.css']
})

export class EmploginComponent implements OnInit {
  EMAIL_ID = "";
  PASSWORD = "";
  passwordVisible = false;
  supportKey = "";
  ORGANIZATION_ID: number;
  isLogedIn = false;
  userAccessData: Useraccessmapping;
  userId = Number(this.cookie.get('userId'));
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData = [];
  isSpinning = false;

  constructor(private router: Router, private api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit(): void {
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      this.router.navigate(['/adminlogin']);

    } else {
      this.isLogedIn = true;
      this.router.navigate(['/common/dashboard']);
    }

    const userId = '1';
    this.api.requestPermission(userId);
  }

  login(): void {
    if (this.EMAIL_ID == "" && this.PASSWORD == "")
      this.message.error("Please enter email id and password", "");

    else {
      this.isSpinning = true;
      this.cookie.deleteAll();
      sessionStorage.clear();

      this.api.login(this.EMAIL_ID, this.PASSWORD).subscribe(data => {
        if (data['code'] == 200) {
          let url = window.location.href;
          let arr = url.split("/");
          let pageName = arr[(arr.length) - 1];

          if (pageName == "orglogin") {
            this.cookie.set('loginType', "1", 365, "/", "", false, "Strict");

          } else {
            this.cookie.set('loginType', "0,1", 365, "/", "", false, "Strict");
          }

          this.cookie.set('token', data["data"][0]["token"], 365, "/", "", false, "Strict");
          this.cookie.set('userId', data["data"][0]['UserData'][0]['USER_ID'], 365, "/", "", false, "Strict");
          this.cookie.set('userName', data["data"][0]['UserData'][0]['NAME'], 365, "/", "", false, "Strict");
          this.cookie.set('roleId', data["data"][0]['UserData'][0]['ROLE_ID'], 365, "/", "", false, "Strict");
          sessionStorage.setItem('userId', data["data"][0]['UserData'][0]['USER_ID']);
          sessionStorage.setItem('roleId', data["data"][0]['UserData'][0]['ROLE_ID']);
          sessionStorage.setItem('emailId', data["data"][0]['UserData'][0]['EMAIL_ID']);
          this.cookie.set('emailId', data["data"][0]['UserData'][0]['EMAIL_ID'], 365, "/", "", false, "Strict")

          this.api.addLog('L', 'Login Successfully', this.cookie.get('emailId')).subscribe(data => {
            // console.log(data);

          }, (err) => {
            if (this.api.checkOnlineStatus()) {
              this.isSpinning = false;
              this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
            } else {
              this.isSpinning = false;
              this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
            }
           });
          window.location.reload();

        } 
        else if (data['code'] == 404) {
          this.isSpinning = false;
          this.message.error(data['message'], "");
        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          this.isSpinning = false;
          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {
          this.isSpinning = false;
          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
       });
    }
  }
}
